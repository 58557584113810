// extracted by mini-css-extract-plugin
export var additionalNotes = "UserBareMetalProject_Details_RequiredProducts__additionalNotes__ALt9j";
export var cardTitle = "UserBareMetalProject_Details_RequiredProducts__cardTitle__Ue8Pr";
export var checkboxes = "UserBareMetalProject_Details_RequiredProducts__checkboxes__PuhM7";
export var checkboxesContainer = "UserBareMetalProject_Details_RequiredProducts__checkboxesContainer__fRDcb";
export var column = "UserBareMetalProject_Details_RequiredProducts__column__rReSy";
export var descriptionHeading = "UserBareMetalProject_Details_RequiredProducts__descriptionHeading__kCCH5";
export var headingGroup = "UserBareMetalProject_Details_RequiredProducts__headingGroup__U5ZeV";
export var headingLicenseGroup = "UserBareMetalProject_Details_RequiredProducts__headingLicenseGroup__m6RAY";
export var headingName = "UserBareMetalProject_Details_RequiredProducts__headingName__ndFU5";
export var lightBackground = "UserBareMetalProject_Details_RequiredProducts__lightBackground__GgA4S";
export var locationCard = "UserBareMetalProject_Details_RequiredProducts__locationCard__qeWUJ";
export var locationText = "UserBareMetalProject_Details_RequiredProducts__locationText__McngX";
export var mapImage = "UserBareMetalProject_Details_RequiredProducts__mapImage__yLyrY";
export var notesContainer = "UserBareMetalProject_Details_RequiredProducts__notesContainer__jRB3_";
export var panel = "UserBareMetalProject_Details_RequiredProducts__panel__hNKCT";
export var panelBody = "UserBareMetalProject_Details_RequiredProducts__panelBody__ss4xr";
export var panelHeading = "UserBareMetalProject_Details_RequiredProducts__panelHeading__GmfZe";
export var productName = "UserBareMetalProject_Details_RequiredProducts__productName__cTM4h";
export var productRow = "UserBareMetalProject_Details_RequiredProducts__productRow__QAeqf";
export var productServiceLabel = "UserBareMetalProject_Details_RequiredProducts__productServiceLabel__FBmIx";
export var productServiceValue = "UserBareMetalProject_Details_RequiredProducts__productServiceValue__xwplC";
export var productsServiceWrapper = "UserBareMetalProject_Details_RequiredProducts__productsServiceWrapper__wgpaD";
export var propertiesContainer = "UserBareMetalProject_Details_RequiredProducts__propertiesContainer__kxWvg";
export var propertyLeft = "UserBareMetalProject_Details_RequiredProducts__propertyLeft__NFoIt";
export var propertyRight = "UserBareMetalProject_Details_RequiredProducts__propertyRight__iB5Gf";
export var requirements = "UserBareMetalProject_Details_RequiredProducts__requirements__SpITb";
export var requirementsBlock = "UserBareMetalProject_Details_RequiredProducts__requirementsBlock__XR1mo";
export var row = "UserBareMetalProject_Details_RequiredProducts__row__Lsmhk";
export var stayShaded = "UserBareMetalProject_Details_RequiredProducts__stayShaded__ixHKk";
export var switchLight = "UserBareMetalProject_Details_RequiredProducts__switchLight__anW4E";
export var switchShaded = "UserBareMetalProject_Details_RequiredProducts__switchShaded__C8EJo";
export var tickmarkIcon = "UserBareMetalProject_Details_RequiredProducts__tickmarkIcon__nL8Je";
export var tileContainer = "UserBareMetalProject_Details_RequiredProducts__tileContainer__U02Xi";
export var tileMap = "UserBareMetalProject_Details_RequiredProducts__tileMap__Jpq6R";
export var withColon = "UserBareMetalProject_Details_RequiredProducts__withColon__onKGl";